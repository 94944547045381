<template>
  <div class="container" v-if="!loading">
    <!-- 祝福语部分 -->
    <div class="zhufu">
      距离云汐的生日还剩
    </div>

    <!-- 倒计时部分 -->
    <div class="Daojishi">
      <div class="Daojishi-item">
        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="'days-' + days" class="Daojishi-box">
            <div class="Daojishi-label">天</div>
            <div class="Daojishi-value">{{ days }}</div>
          </div>
        </transition>
      </div>
      <div class="Daojishi-item">
        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="'hours-' + hours" class="Daojishi-box">
            <div class="Daojishi-label">小时</div>
            <div class="Daojishi-value">{{ hours }}</div>
          </div>
        </transition>
      </div>
      <div class="Daojishi-item">
        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="'minutes-' + minutes" class="Daojishi-box">
            <div class="Daojishi-label">分钟</div>
            <div class="Daojishi-value">{{ minutes }}</div>
          </div>
        </transition>
      </div>
      <div class="Daojishi-item">
        <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="'seconds-' + seconds" class="Daojishi-box">
            <div class="Daojishi-label">秒</div>
            <div class="Daojishi-value">{{ seconds }}</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
  <div class="footer">
    <div class="footer-content">
      <p class="footer-text">
        由强大的 Vue 框架 驱动
      </p>
      <p class="footer-text">
        生活就像一盒巧克力，你永远不知道下一块是什么口味。
      </p>
      <p class="footer-text">
        <a class="footer-link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          <i class="fa-solid fa-shield-check" aria-hidden="true"></i> 苏 ICP 备案 2024040 | 苏公安备案 204570102
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      birthday: new Date('2024-10-12T00:00:00'),
      // 设置生日日期
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      elapsedSeconds: 0,
      currentTime: new Date().toLocaleTimeString(),
      loading: true
    };
  },
  methods: {
    updateDaojishi() {
      const now = new Date();
      const timeDifference = this.birthday - now;
      this.elapsedSeconds = Math.floor((now - new Date(this.birthday.getFullYear(), this.birthday.getMonth(), this.birthday.getDate())) / 1000);
      
      if (timeDifference <= 0) {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        this.milliseconds = 0;
        return;
      }

      this.milliseconds = timeDifference % 1000;
      this.seconds = Math.floor(timeDifference / 1000) % 60;
      this.minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
      this.hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
      this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.currentTime = new Date().toLocaleTimeString();
      document.title = `当前时间：${this.currentTime}`;
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(10px)';
    },
    enter(el, done) {
      el.offsetHeight; // trigger reflow
      el.style.transition = 'opacity 0.5s, transform 0.5s';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s, transform 0.5s';
      el.style.opacity = 0;
      el.style.transform = 'translateY(10px)';
      done();
    },
  },
  mounted() {
    this.updateDaojishi();
    setInterval(() => {
      this.updateDaojishi();
      this.elapsedSeconds += 1; // 更新经过的秒数
      console.log("[DEBUG]" + "更新倒计时");

    }, 1000);
    // 素材加载完成
    setTimeout(() => {
      this.loading = false;
      console.log("[DEBUG]" + "加载完成了喵")
    }, 3000);
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MiSans', 'HarmonyOS Sans', sans-serif;
  overflow: hidden;
}
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: url('http://qwp.kilomaple.cn/wallhaven-wejr1p.webp') center center/cover no-repeat;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(8px);
  z-index: -1;
}
.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  z-index: -1; 
}
.zhufu {
  text-align: center;
  color: black;
  font-size: 2.5em;
  -webkit-text-stroke: 0px black;
  background-color: #E0F7FF; 
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 900px;
  position: relative;
  z-index: 2;
}

.Daojishi {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 900px;
  text-align: center;
  color: #0C73A7;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}

/* 单独的倒计时方块 */
.Daojishi-box {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background: #E0F7FF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* 倒计时数值和标签 */
.Daojishi-value {
  font-size: 2em;
  font-weight: bold;
}

.Daojishi-label {
  font-size: 0.8em;
  color: #333;
}


/* 加载动画 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease, visibility 0.5s ease; /* 动画过渡效果 */
  z-index: 9999;
}

.loading-overlay.hidden {
  opacity: 0;
  visibility: hidden;
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left: 8px solid #0C73A7;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 页尾样式 */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  font-size: 0.8em;
}

.footer-content {
  display: inline-block;
  max-width: 900px;
  width: 100%;
}

.footer-link {
  color: #0C73A7;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* 动画过渡效果 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

</style>
